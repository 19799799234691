define("discourse/plugins/chat/discourse/initializers/chat-user-menu", ["exports", "discourse/lib/plugin-api", "discourse/lib/utilities", "discourse-i18n", "discourse/plugins/chat/discourse/lib/slugify-channel"], function (_exports, _pluginApi, _utilities, _discourseI18n, _slugifyChannel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "chat-user-menu",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("1.3.0", api => {
        const chat = container.lookup("service:chat");
        if (!chat.userCanChat) {
          return;
        }
        if (api.registerNotificationTypeRenderer) {
          api.registerNotificationTypeRenderer("chat_invitation", NotificationItemBase => {
            return class extends NotificationItemBase {
              get linkHref() {
                const slug = (0, _slugifyChannel.default)({
                  title: this.notification.data.chat_channel_title,
                  slug: this.notification.data.chat_channel_slug
                });
                return `/chat/c/${slug || "-"}/${this.notification.data.chat_channel_id}/${this.notification.data.chat_message_id}`;
              }
              get linkTitle() {
                return _discourseI18n.default.t("notifications.titles.chat_invitation");
              }
              get icon() {
                return "link";
              }
              get label() {
                return (0, _utilities.formatUsername)(this.notification.data.invited_by_username);
              }
              get description() {
                return _discourseI18n.default.t("notifications.chat_invitation");
              }
            };
          });
          api.registerNotificationTypeRenderer("chat_mention", NotificationItemBase => {
            return class extends NotificationItemBase {
              get linkHref() {
                const slug = (0, _slugifyChannel.default)({
                  title: this.notification.data.chat_channel_title,
                  slug: this.notification.data.chat_channel_slug
                });
                let notificationRoute = `/chat/c/${slug || "-"}/${this.notification.data.chat_channel_id}`;
                if (this.notification.data.chat_thread_id) {
                  notificationRoute += `/t/${this.notification.data.chat_thread_id}`;
                } else {
                  notificationRoute += `/${this.notification.data.chat_message_id}`;
                }
                return notificationRoute;
              }
              get linkTitle() {
                return _discourseI18n.default.t("notifications.titles.chat_mention");
              }
              get icon() {
                return "d-chat";
              }
              get label() {
                return (0, _utilities.formatUsername)(this.notification.data.mentioned_by_username);
              }
              get description() {
                const identifier = this.notification.data.identifier ? `@${this.notification.data.identifier}` : null;
                const i18nPrefix = this.notification.data.is_direct_message_channel ? "notifications.popup.direct_message_chat_mention" : "notifications.popup.chat_mention";
                const i18nSuffix = identifier ? "other_plain" : "direct";
                return _discourseI18n.default.t(`${i18nPrefix}.${i18nSuffix}`, {
                  identifier,
                  channel: this.notification.data.chat_channel_title
                });
              }
            };
          });
        }
        if (api.registerUserMenuTab) {
          api.registerUserMenuTab(UserMenuTab => {
            return class extends UserMenuTab {
              get id() {
                return "chat-notifications";
              }
              get panelComponent() {
                return "user-menu/chat-notifications-list";
              }
              get icon() {
                return "d-chat";
              }
              get count() {
                return this.getUnreadCountForType("chat_mention") + this.getUnreadCountForType("chat_invitation");
              }
              get notificationTypes() {
                return ["chat_invitation", "chat_mention", "chat_message", "chat_quoted"];
              }
            };
          });
        }
      });
    }
  };
});